.about-section h1 {
  padding-top: 50px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  text-align: center;
  padding-bottom: 100px;
  letter-spacing: 5px;
}
.about-container {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  width: 100%;
}

.title-about {
  margin-left: 210px;
  display: flex;
}

.title-about h2 {
  font-size: 42px;
  font-weight: 900;
  line-height: 1;
  transition: 1s;
}

.title-about h3 {
  display: inline;
  font-size: 18px;
  font-weight: 300;
  margin-right: 10px;
}

.left-line {
  background: #161616;
  height: 100%;
  margin-bottom: 10px;
  width: 2px;
}
.description {
  display: flex;
  flex-direction: row;
  margin-right: 150px;
  width: 50%;
  margin-top: 250px;
}
.description p {
  padding: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 1.2em;
  text-align: right;
  line-height: 1.4;
}
#mypic {
  transition: 1s;
}

.right-line {
  background: #161616;
  height: 100%;
  width: 3px;
}
#cv_icon_download {
  margin-top: -80px;
  margin-left: 185px;
  position: absolute;
}

@media screen and (max-width: 1250px) {
  #mypic {
    width: 150px;
    transition: 1s;
  }
  .description p {
    font-size: 1em;
    transition: 1s;
  }
  .title-about h2 {
    font-size: 33px;
    transition: 1s;
  }
}

@media screen and (max-width: 800px) {
  .about-section {
    height: 60vh;
  }
  .title-about {
    margin-left: 80px;
  }
  #mypic {
    width: 120px;
  }
  .title-about h2 {
    width: 300px;
    font-size: 23px;
    transition: 1s;
  }
  .description {
    margin-top: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 375px) {
  .about-section {
    height: 76vh;
  }
  .about-section h1 {
    font-size: 1.2em;
    padding-bottom: 50px;
    padding-top: 0;
  }
  .left-line {
    background: #161616;
    height: 30%;
    width: 3px;
  }
  .about-container {
    display: block;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
  }
  .description p {
    font-size: 0.65em;
    transition: 1s;
    line-height: 1.1;
  }
  .description {
    display: block;
    flex-direction: row;
    margin-right: 0;
    width: 80%;
    margin-left: 70px;
  }
  .title-about {
    margin-left: 15px;
  }
  #cv_icon_download img {
    width: 30px;
    margin-left: -80px;
    margin-top: 20px;
  }
  #mypic {
    width: 90px;
  }
}
@media screen and (max-width: 414px) {
  .about-section {
    height: 76vh;
  }
  .about-section h1 {
    font-size: 1.2em;
    padding-bottom: 50px;
    padding-top: 0;
  }
  .left-line {
    background: #161616;
    height: 30%;
    width: 3px;
  }
  .about-container {
    display: block;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
  }
  .description p {
    font-size: 0.65em;
    transition: 1s;
    line-height: 1.1;
  }
  .description {
    display: block;
    flex-direction: row;
    margin-right: 0;
    width: 80%;
    margin-left: 70px;
  }
  .title-about {
    margin-left: 15px;
  }
  #cv_icon_download img {
    width: 30px;
    margin-left: -80px;
    margin-top: 20px;
  }
  #mypic {
    width: 90px;
  }
}

@media screen and (max-width: 320px) {
  .description {
    margin-left: 47px;
  }
  .description p {
    padding: 5px;
  }
  .about-container {
    margin-top: 0px;
  }
}
@media screen and (max-height: 320px) {
  .about-section {
    height: 76vh;
  }
  .about-section h1 {
    font-size: 1.2em;
    padding-bottom: 50px;
    padding-top: 0;
  }
  .left-line {
    background: #161616;
    height: 30%;
    width: 3px;
  }
  .about-container {
    display: block;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
  }
  .description p {
    font-size: 0.65em;
    transition: 1s;
    line-height: 1.1;
  }
  .description {
    display: block;
    flex-direction: row;
    margin-right: 0;
    width: 80%;
    margin-left: 70px;
  }
  .title-about {
    margin-left: 15px;
  }
  #cv_icon_download img {
    width: 30px;
    margin-left: -80px;
    margin-top: 20px;
  }
  #mypic {
    width: 90px;
  }
}

@media screen and (max-width: 320px) {
  .description {
    margin-left: 47px;
  }
  .description p {
    padding: 5px;
  }
  .about-container {
    margin-top: 0px;
  }
}
@media screen and (max-width: 640px) and (max-height: 360px) and (orientation: landscape) {
  .about-section {
    height: 150vh;
  }
  .about-section h1 {
    font-size: 1.2em;
    padding-bottom: 50px;
    padding-top: 0;
  }
  .description {
    margin-left: 10px;
  }
  .description p {
    padding: 25px;
    font-size: 0.75em;
  }
  .about-container {
    margin-top: 0px;
  }
}
@media screen and (max-width: 667px) and (max-height: 375px) and (orientation: landscape) {
  .about-section {
    height: 128vh;
  }
  .about-section h1 {
    font-size: 1.2em;
    padding-bottom: 50px;
    padding-top: 0;
  }
  .description {
    margin-left: 10px;
  }
  .description p {
    padding: 25px;
    font-size: 0.75em;
  }
  .about-container {
    margin-top: 0px;
  }
}
@media screen and (max-width: 736px) and (max-height: 414px) and (orientation: landscape) {
  .about-section {
    height: 128vh;
  }
  .about-section h1 {
    font-size: 1.2em;
    padding-bottom: 50px;
    padding-top: 0;
  }
  .description {
    margin-left: 10px;
  }
  .description p {
    padding: 25px;
    font-size: 0.75em;
  }
  .about-container {
    margin-top: 0px;
  }
}

@media screen and (max-width: 812px) and (max-height: 375px) and (orientation: landscape) {
  .about-section {
    height: 216vh;
  }
  .about-section h1 {
    font-size: 1.2em;
    padding-bottom: 50px;
    padding-top: 0;
  }
  .description {
    margin-left: 10px;
  }
  .description p {
    padding: 25px;
    font-size: 0.75em;
  }
  .about-container {
    margin-top: 0px;
  }
}
