*,
html {
  margin: 0;
  padding: 0;
}

.active {
  color: #44aeeb;
  transition: 1s;
}
.contact-active {
  color: #44aeeb;
  transition: 1s;
}

.navbar {
  position: fixed;
  width: 100%;
  z-index: 99;
  padding-top: 40px;
  transition: 1s;
}
.menu-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
}
.menu-wrap .toggler {
  position: absolute;
  top: 0;
  left: 0;
  float: right;
  z-index: 99;
  cursor: pointer;
  width: 40px;
  height: 40px;
  opacity: 0;
}
.menu-wrap .hamburger {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 20px;
  height: 20px;
  padding: 0.75rem;
  background: -moz-linear-gradient(
    50% 96.29% 90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(41, 171, 226, 0.7) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(41, 171, 226, 0.7) 100%
  );
  background: -webkit-gradient(
    linear,
    50% 96.29%,
    50% -5%,
    color-stop(0, rgba(0, 0, 0, 0)),
    color-stop(1, rgba(41, 171, 226, 0.7))
  );
  background: -o-linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(41, 171, 226, 0.7) 100%
  );
  background: -ms-linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(41, 171, 226, 0.7) 100%
  );
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#29ABE2' ,GradientType=0)";
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(41, 171, 226, 0.7) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#29ABE2',endColorstr='#000000' , GradientType=0);
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-wrap .hamburger > div {
  position: relative;
  width: 100%;
  height: 2px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}
.menu-wrap .hamburger > div::before,
.menu-wrap .hamburger > div::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -7px;
  width: 100%;
  height: 2px;
  background: #fff;
}
.menu-wrap .hamburger > div::after {
  top: 7px;
}
.menu-wrap .toggler:checked + .hamburger > div {
  transform: rotate(135deg);
}

.menu-wrap .toggler:checked + .hamburger > div::before,
.menu-wrap .toggler:checked + .hamburger > div::after {
  top: 0;
  transform: rotate(90deg);
}

.menu-wrap .toggler:checked ~ .menu {
  visibility: visible;
}
.menu-wrap .toggler:checked ~ .menu > div {
  transform: scale(1);
  transition-duration: 1s;
}
.menu-wrap .toggler:checked ~ .menu > div > div {
  opacity: 1;
  transition: opacity 0.4s ease;
}

.menu-wrap .menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 43px;
  visibility: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-wrap .menu > div {
  background: -moz-linear-gradient(
    0% 50% 0deg,
    rgba(22, 22, 22, 0) 0%,
    rgba(28, 66, 82, 0.23) 15.76%,
    rgba(33, 111, 143, 0.5) 33.57%,
    rgba(37, 143, 188, 0.72) 48.71%,
    rgba(40, 164, 216, 0.9) 60.39%,
    rgba(41, 171, 226, 1) 67.2%,
    rgba(40, 163, 214, 1) 68.57%,
    rgba(34, 113, 147, 1) 77.1%,
    rgba(29, 74, 93, 1) 84.81%,
    rgba(25, 46, 54, 1) 91.44%,
    rgba(23, 28, 31, 1) 96.72%,
    rgba(22, 22, 22, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(22, 22, 22, 0) 0%,
    rgba(28, 66, 82, 0.23) 15.76%,
    rgba(33, 111, 143, 0.5) 33.57%,
    rgba(37, 143, 188, 0.72) 48.71%,
    rgba(40, 164, 216, 0.9) 60.39%,
    rgba(41, 171, 226, 1) 67.2%,
    rgba(40, 163, 214, 1) 68.57%,
    rgba(34, 113, 147, 1) 77.1%,
    rgba(29, 74, 93, 1) 84.81%,
    rgba(25, 46, 54, 1) 91.44%,
    rgba(23, 28, 31, 1) 96.72%,
    rgba(22, 22, 22, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    0% 50%,
    100% 50%,
    color-stop(0, rgba(22, 22, 22, 0)),
    color-stop(0.1576, rgba(28, 66, 82, 0.23)),
    color-stop(0.3357, rgba(33, 111, 143, 0.5)),
    color-stop(0.4871, rgba(37, 143, 188, 0.72)),
    color-stop(0.6039, rgba(40, 164, 216, 0.9)),
    color-stop(0.672, rgba(41, 171, 226, 1)),
    color-stop(0.6857, rgba(40, 163, 214, 1)),
    color-stop(0.771, rgba(34, 113, 147, 1)),
    color-stop(0.8481, rgba(29, 74, 93, 1)),
    color-stop(0.9144, rgba(25, 46, 54, 1)),
    color-stop(0.9672, rgba(23, 28, 31, 1)),
    color-stop(1, rgba(22, 22, 22, 1))
  );
  background: -o-linear-gradient(
    0deg,
    rgba(22, 22, 22, 0) 0%,
    rgba(28, 66, 82, 0.23) 15.76%,
    rgba(33, 111, 143, 0.5) 33.57%,
    rgba(37, 143, 188, 0.72) 48.71%,
    rgba(40, 164, 216, 0.9) 60.39%,
    rgba(41, 171, 226, 1) 67.2%,
    rgba(40, 163, 214, 1) 68.57%,
    rgba(34, 113, 147, 1) 77.1%,
    rgba(29, 74, 93, 1) 84.81%,
    rgba(25, 46, 54, 1) 91.44%,
    rgba(23, 28, 31, 1) 96.72%,
    rgba(22, 22, 22, 1) 100%
  );
  background: -ms-linear-gradient(
    0deg,
    rgba(22, 22, 22, 0) 0%,
    rgba(28, 66, 82, 0.23) 15.76%,
    rgba(33, 111, 143, 0.5) 33.57%,
    rgba(37, 143, 188, 0.72) 48.71%,
    rgba(40, 164, 216, 0.9) 60.39%,
    rgba(41, 171, 226, 1) 67.2%,
    rgba(40, 163, 214, 1) 68.57%,
    rgba(34, 113, 147, 1) 77.1%,
    rgba(29, 74, 93, 1) 84.81%,
    rgba(25, 46, 54, 1) 91.44%,
    rgba(23, 28, 31, 1) 96.72%,
    rgba(22, 22, 22, 1) 100%
  );
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#161616', endColorstr='#161616' ,GradientType=0)";
  background: linear-gradient(
    90deg,
    rgba(22, 22, 22, 0) 0%,
    rgba(28, 66, 82, 0.23) 15.76%,
    rgba(33, 111, 143, 0.5) 33.57%,
    rgba(37, 143, 188, 0.72) 48.71%,
    rgba(40, 164, 216, 0.9) 60.39%,
    rgba(41, 171, 226, 1) 67.2%,
    rgba(40, 163, 214, 1) 68.57%,
    rgba(34, 113, 147, 1) 77.1%,
    rgba(29, 74, 93, 1) 84.81%,
    rgba(25, 46, 54, 1) 91.44%,
    rgba(23, 28, 31, 1) 96.72%,
    rgba(22, 22, 22, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#161616',endColorstr='#161616' , GradientType=1);
  opacity: 1;

  width: 100vw;
  height: 200vw;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: flex-end;
  transform: scale(0);
  transition: all 0.4s ease;
}
.menu-wrap .menu > div > div {
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
  opacity: 0;
  transition: opacity 0.4s ease;
}
.menu-wrap .menu > div > div > ul > li {
  font-family: "Montserrat", sans-serif;
  list-style: none;
  color: #fff;
  font-size: 0.9rem;
  padding: 1rem;
  display: inline;
}

.navbar ul {
  display: inline;
  list-style: none;
  text-align: right;
  margin-right: 150px;
  cursor: pointer;
  float: right;
}

.navbar ul {
  font-family: "Montserrat", sans-serif;

  color: #44aeeb;
  display: inline;
  text-decoration: none;
}

.navbar ul a {
  margin-right: 20px;
}
.logo-name {
  margin-top: 90px;
  margin-left: 200px;
}

.down_arrow {
  border: solid #fff;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  margin-left: 5px;
}

#cv_link {
  position: absolute;
  right: 100px;
  font-size: 0.7em;
  text-decoration: none;
  color: #fff;
}

#logo_white {
  width: 133px;
  display: none;
  cursor: pointer;
}

.main-text {
  display: block;
  margin-top: 250px;
  margin-left: auto;
  margin-right: auto;
}

.first-section {
  height: 110vh;
  background-image: url(FirstBackground.svg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.main-skills {
  height: 110vh;
  background-image: url(MainSkillsBackground.jpg);
  background-size: contain;
  background-repeat: no-repeat;
}
.about-section {
  height: 130vh;
  background-image: url(AboutBackground.jpg);
  background-size: contain;
  background-repeat: no-repeat;
}

.border-right {
  background: -moz-linear-gradient(
    0% 50% 0deg,
    rgba(22, 22, 22, 0) 0%,
    rgba(22, 24, 25, 0.18) 18.2%,
    rgba(23, 32, 35, 0.33) 32.53%,
    rgba(25, 44, 52, 0.46) 45.55%,
    rgba(27, 61, 75, 0.58) 57.8%,
    rgba(30, 83, 106, 0.7) 69.5%,
    rgba(33, 110, 143, 0.81) 80.79%,
    rgba(37, 142, 186, 0.92) 91.55%,
    rgba(41, 171, 226, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(22, 22, 22, 0) 0%,
    rgba(22, 24, 25, 0.18) 18.2%,
    rgba(23, 32, 35, 0.33) 32.53%,
    rgba(25, 44, 52, 0.46) 45.55%,
    rgba(27, 61, 75, 0.58) 57.8%,
    rgba(30, 83, 106, 0.7) 69.5%,
    rgba(33, 110, 143, 0.81) 80.79%,
    rgba(37, 142, 186, 0.92) 91.55%,
    rgba(41, 171, 226, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    0% 50%,
    100% 50%,
    color-stop(0, rgba(22, 22, 22, 0)),
    color-stop(0.182, rgba(22, 24, 25, 0.18)),
    color-stop(0.3253, rgba(23, 32, 35, 0.33)),
    color-stop(0.4555, rgba(25, 44, 52, 0.46)),
    color-stop(0.578, rgba(27, 61, 75, 0.58)),
    color-stop(0.695, rgba(30, 83, 106, 0.7)),
    color-stop(0.8079, rgba(33, 110, 143, 0.81)),
    color-stop(0.9155, rgba(37, 142, 186, 0.92)),
    color-stop(1, rgba(41, 171, 226, 1))
  );
  background: -o-linear-gradient(
    0deg,
    rgba(22, 22, 22, 0) 0%,
    rgba(22, 24, 25, 0.18) 18.2%,
    rgba(23, 32, 35, 0.33) 32.53%,
    rgba(25, 44, 52, 0.46) 45.55%,
    rgba(27, 61, 75, 0.58) 57.8%,
    rgba(30, 83, 106, 0.7) 69.5%,
    rgba(33, 110, 143, 0.81) 80.79%,
    rgba(37, 142, 186, 0.92) 91.55%,
    rgba(41, 171, 226, 1) 100%
  );
  background: -ms-linear-gradient(
    0deg,
    rgba(22, 22, 22, 0) 0%,
    rgba(22, 24, 25, 0.18) 18.2%,
    rgba(23, 32, 35, 0.33) 32.53%,
    rgba(25, 44, 52, 0.46) 45.55%,
    rgba(27, 61, 75, 0.58) 57.8%,
    rgba(30, 83, 106, 0.7) 69.5%,
    rgba(33, 110, 143, 0.81) 80.79%,
    rgba(37, 142, 186, 0.92) 91.55%,
    rgba(41, 171, 226, 1) 100%
  );
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#161616', endColorstr='#29ABE2' ,GradientType=0)";
  background: linear-gradient(
    90deg,
    rgba(22, 22, 22, 0) 0%,
    rgba(22, 24, 25, 0.18) 18.2%,
    rgba(23, 32, 35, 0.33) 32.53%,
    rgba(25, 44, 52, 0.46) 45.55%,
    rgba(27, 61, 75, 0.58) 57.8%,
    rgba(30, 83, 106, 0.7) 69.5%,
    rgba(33, 110, 143, 0.81) 80.79%,
    rgba(37, 142, 186, 0.92) 91.55%,
    rgba(41, 171, 226, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#161616',endColorstr='#29ABE2' , GradientType=1);
  height: 110vh;
  width: 82px;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.7;
}

.border-right-orange {
  background: -moz-linear-gradient(
    0% 50% 0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 253, 252, 0.18) 17.68%,
    rgba(254, 245, 242, 0.32) 31.6%,
    rgba(253, 233, 225, 0.44) 44.26%,
    rgba(252, 215, 202, 0.56) 56.15%,
    rgba(250, 192, 171, 0.68) 67.52%,
    rgba(247, 164, 134, 0.78) 78.5%,
    rgba(244, 130, 90, 0.89) 89.16%,
    rgba(241, 93, 40, 0.99) 99.31%,
    rgba(241, 90, 36, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 253, 252, 0.18) 17.68%,
    rgba(254, 245, 242, 0.32) 31.6%,
    rgba(253, 233, 225, 0.44) 44.26%,
    rgba(252, 215, 202, 0.56) 56.15%,
    rgba(250, 192, 171, 0.68) 67.52%,
    rgba(247, 164, 134, 0.78) 78.5%,
    rgba(244, 130, 90, 0.89) 89.16%,
    rgba(241, 93, 40, 0.99) 99.31%,
    rgba(241, 90, 36, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    0% 50%,
    100% 50%,
    color-stop(0, rgba(255, 255, 255, 0)),
    color-stop(0.1768, rgba(255, 253, 252, 0.18)),
    color-stop(0.316, rgba(254, 245, 242, 0.32)),
    color-stop(0.4426, rgba(253, 233, 225, 0.44)),
    color-stop(0.5615, rgba(252, 215, 202, 0.56)),
    color-stop(0.6752, rgba(250, 192, 171, 0.68)),
    color-stop(0.785, rgba(247, 164, 134, 0.78)),
    color-stop(0.8916, rgba(244, 130, 90, 0.89)),
    color-stop(0.9931, rgba(241, 93, 40, 0.99)),
    color-stop(1, rgba(241, 90, 36, 1))
  );
  background: -o-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 253, 252, 0.18) 17.68%,
    rgba(254, 245, 242, 0.32) 31.6%,
    rgba(253, 233, 225, 0.44) 44.26%,
    rgba(252, 215, 202, 0.56) 56.15%,
    rgba(250, 192, 171, 0.68) 67.52%,
    rgba(247, 164, 134, 0.78) 78.5%,
    rgba(244, 130, 90, 0.89) 89.16%,
    rgba(241, 93, 40, 0.99) 99.31%,
    rgba(241, 90, 36, 1) 100%
  );
  background: -ms-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 253, 252, 0.18) 17.68%,
    rgba(254, 245, 242, 0.32) 31.6%,
    rgba(253, 233, 225, 0.44) 44.26%,
    rgba(252, 215, 202, 0.56) 56.15%,
    rgba(250, 192, 171, 0.68) 67.52%,
    rgba(247, 164, 134, 0.78) 78.5%,
    rgba(244, 130, 90, 0.89) 89.16%,
    rgba(241, 93, 40, 0.99) 99.31%,
    rgba(241, 90, 36, 1) 100%
  );
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#F15A24' ,GradientType=0)";
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 253, 252, 0.18) 17.68%,
    rgba(254, 245, 242, 0.32) 31.6%,
    rgba(253, 233, 225, 0.44) 44.26%,
    rgba(252, 215, 202, 0.56) 56.15%,
    rgba(250, 192, 171, 0.68) 67.52%,
    rgba(247, 164, 134, 0.78) 78.5%,
    rgba(244, 130, 90, 0.89) 89.16%,
    rgba(241, 93, 40, 0.99) 99.31%,
    rgba(241, 90, 36, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#F15A24' , GradientType=1);
  height: 110vh;
  width: 82px;
  position: absolute;
  right: 0;
  opacity: 0.7;
}
.border-right-green {
  background: -moz-linear-gradient(
    0% 50% 0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(252, 255, 252, 0.17) 16.61%,
    rgba(242, 255, 242, 0.3) 29.7%,
    rgba(225, 255, 225, 0.42) 41.6%,
    rgba(202, 255, 202, 0.53) 52.79%,
    rgba(171, 255, 171, 0.63) 63.48%,
    rgba(134, 255, 134, 0.74) 73.8%,
    rgba(90, 255, 90, 0.84) 83.81%,
    rgba(40, 255, 40, 0.93) 93.34%,
    rgba(0, 255, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(252, 255, 252, 0.17) 16.61%,
    rgba(242, 255, 242, 0.3) 29.7%,
    rgba(225, 255, 225, 0.42) 41.6%,
    rgba(202, 255, 202, 0.53) 52.79%,
    rgba(171, 255, 171, 0.63) 63.48%,
    rgba(134, 255, 134, 0.74) 73.8%,
    rgba(90, 255, 90, 0.84) 83.81%,
    rgba(40, 255, 40, 0.93) 93.34%,
    rgba(0, 255, 0, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    0% 50%,
    100% 50%,
    color-stop(0, rgba(255, 255, 255, 0)),
    color-stop(0.1661, rgba(252, 255, 252, 0.17)),
    color-stop(0.297, rgba(242, 255, 242, 0.3)),
    color-stop(0.416, rgba(225, 255, 225, 0.42)),
    color-stop(0.5279, rgba(202, 255, 202, 0.53)),
    color-stop(0.6348, rgba(171, 255, 171, 0.63)),
    color-stop(0.738, rgba(134, 255, 134, 0.74)),
    color-stop(0.8381, rgba(90, 255, 90, 0.84)),
    color-stop(0.9334, rgba(40, 255, 40, 0.93)),
    color-stop(1, rgba(0, 255, 0, 1))
  );
  background: -o-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(252, 255, 252, 0.17) 16.61%,
    rgba(242, 255, 242, 0.3) 29.7%,
    rgba(225, 255, 225, 0.42) 41.6%,
    rgba(202, 255, 202, 0.53) 52.79%,
    rgba(171, 255, 171, 0.63) 63.48%,
    rgba(134, 255, 134, 0.74) 73.8%,
    rgba(90, 255, 90, 0.84) 83.81%,
    rgba(40, 255, 40, 0.93) 93.34%,
    rgba(0, 255, 0, 1) 100%
  );
  background: -ms-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(252, 255, 252, 0.17) 16.61%,
    rgba(242, 255, 242, 0.3) 29.7%,
    rgba(225, 255, 225, 0.42) 41.6%,
    rgba(202, 255, 202, 0.53) 52.79%,
    rgba(171, 255, 171, 0.63) 63.48%,
    rgba(134, 255, 134, 0.74) 73.8%,
    rgba(90, 255, 90, 0.84) 83.81%,
    rgba(40, 255, 40, 0.93) 93.34%,
    rgba(0, 255, 0, 1) 100%
  );
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#00FF00' ,GradientType=0)";
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(252, 255, 252, 0.17) 16.61%,
    rgba(242, 255, 242, 0.3) 29.7%,
    rgba(225, 255, 225, 0.42) 41.6%,
    rgba(202, 255, 202, 0.53) 52.79%,
    rgba(171, 255, 171, 0.63) 63.48%,
    rgba(134, 255, 134, 0.74) 73.8%,
    rgba(90, 255, 90, 0.84) 83.81%,
    rgba(40, 255, 40, 0.93) 93.34%,
    rgba(0, 255, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#00FF00' , GradientType=1);
  height: 110vh;
  width: 82px;
  position: absolute;
  right: 0;
  opacity: 0.7;
}

@media screen and (max-width: 1333px) {
  .first-section {
    background-image: url(FirstBackgroundMobile.svg);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    transition: ease 0.4s;
  }
}
@media screen and (max-width: 414px), (max-width: 375px) {
  .first-section {
    height: 147vh;
    background-image: url(FirstBackgroundMobilecss.svg);
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
    background-repeat: no-repeat;
    transition: ease 0.4s;
  }
  .navbar {
    display: none;
  }
}

@media screen and (min-width: 416px) {
  .menu-wrap {
    display: none;
  }
}
