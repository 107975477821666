.portfolio-section {
  padding-top: 100px;
  height: 340vh;
}

.portfolio-section h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  text-align: center;
  color: #161616;
  letter-spacing: 5px;
  margin-bottom: 150px;
}

.package {
  margin-top: 50px;
  width: 100%;
  display: flex;
  height: auto;
}

.logo_design {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  height: 60vh;
  height: auto;
}

.web_design {
  margin-top: 50px;
  width: 100%;
  display: flex;
  height: 60vh;
  height: auto;
}

.web_app {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  height: 60vh;
  height: auto;
}

.port_section h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  background: #161616;
  color: #fff;
  padding: 100px;
  margin-right: 0;
  margin-left: 0px;
  font-size: 3em;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transition: 1s;
}
.carousel-package {
  width: 1000px;
  /* border: 1px solid orange; */
  margin-left: 0;
}
.carousel__back-button {
  border: none;
  background: none;
  outline: none;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 50px;
  transition: 0.6s;
}
.carousel__next-button {
  border: none;
  background: none;
  outline: none;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 800px;
  transition: 0.6s;
}

.carousel__back-button :hover {
  opacity: 0.6;
  transition: 0.6s;
}

.carousel__next-button :hover {
  opacity: 0.6;
  transition: 0.6s;
}

.carousel__inner-slide {
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: row;
  height: 373px;
}

.desc-carousel h2 {
  color: #161616;
  font-size: 2.5em;
  font-weight: 900;
  margin-bottom: 20px;
}
.desc-carousel p {
  text-align: left;
  padding: 10px;
}

@media screen and (max-width: 1250px) {
  .carousel__next-button {
    margin-left: 600px;
  }
}
@media screen and (max-width: 1050px) {
  .carousel__next-button {
    margin-left: 500px;
  }
  .port_section h1 {
    padding: 60px;
    transition: 1s;
  }
}

@media screen and (max-width: 1440px) {
  .portfolio-section h1 {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .port_section h1 {
    padding: 13px;
    transition: 1s;
    font-size: 2.1em;
  }
  .carousel__next-button {
    margin-left: 400px;
  }
  .portfolio-section {
    height: 228vh;
  }
  .desc-carousel h2 {
    font-size: 1.8em;
  }
  .desc-carousel p {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 414px), (max-width: 375px) {
  #title-port {
    font-size: 1.2em;
    margin-bottom: 100px;
  }
  .port_section h1 {
    padding: 13px;
    transition: 1s;
    font-size: 0.9em;
    letter-spacing: 1px;
    font-weight: 800;
    margin-bottom: 0;
  }
  .carousel__next-button {
    margin-left: 200px;
  }
  .portfolio-section {
    height: 170vh;
  }
  .desc-carousel h2 {
    font-size: 0.8em;
    margin-bottom: 10px;
  }
  .desc-carousel p {
    font-size: 0.5em;
  }
  .carousel__back-button {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
    transition: 1s;
  }
  .carousel__next-button {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 100px;
    transition: 1s;
  }
  .carousel__back-button img {
    width: 23px;
  }
  .carousel__next-button img {
    width: 23px;
  }
  .carousel__inner-slide img {
    width: 166.667px;
    height: 124.333px;
  }
}
@media screen and (max-width: 640px) and (max-height: 360px) and (orientation: landscape) {
  #title-port {
    font-size: 1.2em;
    margin-bottom: 100px;
  }
  .port_section h1 {
    padding: 13px;
    transition: 1s;
    font-size: 1.2em;
    letter-spacing: 1px;
    font-weight: 800;
    margin-bottom: 0;
  }
  .carousel__next-button {
    margin-left: 200px;
  }
  .portfolio-section {
    height: 450vh;
    padding-top: 0;
  }
  .desc-carousel h2 {
    font-size: 0.9em;
    margin-bottom: 10px;
  }
  .desc-carousel p {
    font-size: 0.7em;
  }
  .carousel__back-button {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
    transition: 1s;
  }
  .carousel__next-button {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 100px;
    transition: 1s;
  }
  .carousel__back-button img {
    width: 23px;
  }
  .carousel__next-button img {
    width: 23px;
    margin-left: 120px;
  }
  .carousel__inner-slide img {
    width: 333.334px;
    height: 248.666px;
  }
}
@media screen and (max-width: 667px) and (max-height: 375px) and (orientation: landscape) {
  #title-port {
    font-size: 1.2em;
    margin-bottom: 100px;
  }
  .port_section h1 {
    padding: 13px;
    transition: 1s;
    font-size: 1.2em;
    letter-spacing: 1px;
    font-weight: 800;
    margin-bottom: 0;
  }
  .carousel__next-button {
    margin-left: 200px;
  }
  .portfolio-section {
    height: 450vh;
    padding-top: 0;
  }
  .desc-carousel h2 {
    font-size: 0.9em;
    margin-bottom: 10px;
  }
  .desc-carousel p {
    font-size: 0.7em;
  }
  .carousel__back-button {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
    transition: 1s;
  }
  .carousel__next-button {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 100px;
    transition: 1s;
  }
  .carousel__back-button img {
    width: 23px;
  }
  .carousel__next-button img {
    width: 23px;
    margin-left: 120px;
  }
  .carousel__inner-slide img {
    width: 333.334px;
    height: 248.666px;
  }
}
@media screen and (max-width: 736px) and (max-height: 414px) and (orientation: landscape) {
  #title-port {
    font-size: 1.2em;
    margin-bottom: 100px;
  }
  .port_section h1 {
    padding: 13px;
    transition: 1s;
    font-size: 1.2em;
    letter-spacing: 1px;
    font-weight: 800;
    margin-bottom: 0;
  }
  .carousel__next-button {
    margin-left: 200px;
  }
  .portfolio-section {
    height: 450vh;
    padding-top: 0;
  }
  .desc-carousel h2 {
    font-size: 0.9em;
    margin-bottom: 10px;
  }
  .desc-carousel p {
    font-size: 0.7em;
  }
  .carousel__back-button {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
    transition: 1s;
  }
  .carousel__next-button {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 100px;
    transition: 1s;
  }
  .carousel__back-button img {
    width: 23px;
  }
  .carousel__next-button img {
    width: 23px;
    margin-left: 120px;
  }
  .carousel__inner-slide img {
    width: 333.334px;
    height: 248.666px;
  }
}

@media screen and (max-width: 812px) and (max-height: 375px) and (orientation: landscape) {
  #title-port {
    font-size: 1.2em;
    margin-bottom: 100px;
  }
  .port_section h1 {
    padding: 13px;
    transition: 1s;
    font-size: 1.2em;
    letter-spacing: 1px;
    font-weight: 800;
    margin-bottom: 0;
  }
  .carousel__next-button {
    margin-left: 200px;
  }
  .portfolio-section {
    height: 510vh;
    padding-top: 0;
  }
  .desc-carousel h2 {
    font-size: 0.9em;
    margin-bottom: 10px;
  }
  .desc-carousel p {
    font-size: 0.7em;
  }
  .carousel__back-button {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
    transition: 1s;
  }
  .carousel__next-button {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 100px;
    transition: 1s;
  }
  .carousel__back-button img {
    width: 23px;
  }
  .carousel__next-button img {
    width: 23px;
    margin-left: 120px;
  }
  .carousel__inner-slide img {
    width: 333.334px;
    height: 248.666px;
  }
}
