.main-skills {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  text-align: right;
  align-items: flex-end;
  padding-bottom: 300px;
  height: 100vh;
}
.top-triangle {
  z-index: 96;
  position: relative;
  width: 100%;
  height: 100px;
  top: 10;
  left: 0;
  right: 0;
  padding-bottom: 10%;
  background: #161616;
  clip-path: polygon(0% 0%, 100% 0%, 50% 60%);
}

.right-element {
  width: 50%;
}
.main-skills h1 {
  padding-top: 50px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #f15a24;
  font-size: 2rem;
  margin-right: 400px;
  margin-bottom: 50px;
}
.main-skills h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-size: 2rem;
  text-align: left;
}
.main-skills h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  color: #161616;
  font-size: 2rem;
  text-align: left;
  padding-top: 20px;
}
.line {
  display: block;
  width: 0;
  margin-bottom: 30px;
  -webkit-animation-name: border;
  -webkit-animation-duration: 2s;
  animation-name: border;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
#line {
  display: block;
}
@-webkit-keyframes border {
  from {
    width: 0;
  }
  to {
    width: 60%;
  }
}
@keyframes border {
  from {
    width: 0;
  }
  to {
    width: 60%;
  }
}
.color1 {
  border-bottom: 2px solid #f15a24;
  animation-delay: 1s;
}
.color2 {
  border-bottom: 2px solid #00ffff;
  animation-delay: 2s;
}
.color3 {
  border-bottom: 2px solid #00ff00;
  animation-delay: 3s;
}
.color4 {
  border-bottom: 2px solid #9e005d;
  animation-delay: 4s;
}

@media screen and (max-width: 768px) {
  .main-skills {
    height: 80vh;
    padding-bottom: 10px;
  }
  .right-element {
    margin-right: 50px;
  }
}

@media screen and (max-width: 414px), (max-width: 375px) {
  .main-skills {
    height: 106vh;
  }
  .main-skills h1 {
    font-size: 1.2em;
    margin-right: 50px;
  }
  .main-skills h2 {
    font-size: 0.8em;
  }
  .main-skills h3 {
    font-size: 0.8em;
    padding-top: 10px;
  }
  .right-element {
    width: 60%;
    margin-right: 10px;
  }
}

@media screen and (max-height: 812px) {
  .main-skills {
    height: 110vh;
  }
}

@media screen and (max-height: 320px), (max-height: 375px) {
  .main-skills {
    height: 106vh;
  }
  .main-skills h1 {
    font-size: 1.4em;
    margin-right: 50px;
  }
  .main-skills h2 {
    font-size: 0.9em;
  }
  .main-skills h3 {
    font-size: 0.9em;
    padding-top: 10px;
  }
  .right-element {
    width: 60%;
    margin-right: 10px;
  }
}
@media screen and (max-width: 640px) and (max-height: 360px) and (orientation: landscape) {
  .main-skills {
    height: 186vh;
  }
}
@media screen and (max-width: 667px) and (max-height: 375px) and (orientation: landscape) {
  .main-skills {
    height: 168vh;
  }
}
@media screen and (max-width: 736px) and (max-height: 414px) and (orientation: landscape) {
  .main-skills {
    height: 148vh;
  }

  .main-skills h1 {
    font-size: 1.4em;
    margin-right: 50px;
  }
  .main-skills h2 {
    font-size: 0.9em;
  }
  .main-skills h3 {
    font-size: 1em;
    padding-top: 10px;
  }
  .right-element {
    width: 60%;
    margin-right: 10px;
  }
}
