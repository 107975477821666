.scroll-show a span {
  position: absolute;
  top: 93%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 4px solid #1b7da7;
  border-bottom: 4px solid #32c1ff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb04 2s infinite;
  animation: sdb04 2s infinite;
  box-sizing: border-box;
  cursor: pointer;
}

@-webkit-keyframes sdb04 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    -webkit-transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
}
@keyframes sdb04 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
}

@media screen and (max-width: 768px) {
  .logo-name {
    margin-left: 80px;
  }
  .navbar ul {
    margin-right: 50px;
  }
}
@media screen and (max-width: 414px) {
  .logo-name {
    margin-left: 132px;
    width: 150px;
  }
}

@media screen and (max-width: 375px) {
  .logo-name {
    margin-left: 112.5px;
    width: 150px;
  }
}

@media screen and (max-width: 360px) {
  .logo-name {
    margin-left: 105px;
  }
}

@media screen and (max-width: 320px) {
  .logo-name {
    margin-left: 85px;
  }
}

@media screen and (max-width: 640px) and (max-height: 360px) and (orientation: landscape) {
  .navbar {
    display: none;
  }
  .menu-wrap {
    display: flex;
  }
  .scroll-show a span {
    top: 85%;
  }
  .logo-name {
    width: 120px;
    position: relative;
    margin-top: 0;
    float: right;
    padding: 15px;
  }
}

@media screen and (max-width: 667px) and (max-height: 375px) and (orientation: landscape) {
  .navbar {
    display: none;
  }
  .menu-wrap {
    display: flex;
  }
  .scroll-show a span {
    top: 85%;
  }
  .logo-name {
    width: 120px;
    position: relative;
    margin-top: 0;
    float: right;
    padding: 15px;
  }
}

@media screen and (max-width: 736px) and (max-height: 414px) and (orientation: landscape) {
  .navbar {
    display: none;
  }
  .menu-wrap {
    display: flex;
  }
  .scroll-show a span {
    top: 85%;
  }
  .logo-name {
    width: 120px;
    position: relative;
    margin-top: 0;
    float: right;
    padding: 15px;
  }
}

@media screen and (max-width: 812px) and (max-height: 375px) and (orientation: landscape) {
  .navbar {
    display: none;
  }
  .menu-wrap {
    display: flex;
  }
  .scroll-show a span {
    top: 85%;
  }
  .logo-name {
    width: 120px;
    position: relative;
    margin-top: 0;
    float: right;
    padding: 15px;
  }
}
