.bottom-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2 00px;
  background: #161616;
  padding-bottom: 10px;
}

.tel-num {
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  transition: ease 0.5s;
}
.tel-num:hover {
  color: #1b7da7;
  transition: ease 0.5s;
}

#rights {
  font-size: 0.8em;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 100;
  text-align: center;
  display: block;
}

.to-top {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.contact {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
#rights {
  padding-top: 50px;
}
.cont {
  margin: 10px;
}
a {
  text-decoration: none;
}

.dot {
  width: 24px;
  height: 24px;
  border-left: 4px solid #161616;
  border-bottom: 4px solid #2e2e2e;
  transform: rotate(135deg);
  cursor: pointer;
  box-sizing: border-box;
  -webkit-transform: rotate(-225deg);
  transform: rotate(-225deg);
  -webkit-animation: totop 4 2s infinite;
  animation: totop 2s infinite;
}
@-webkit-keyframes totop {
  0% {
    -webkit-transform: rotate(-225deg) translate(0, 0);
  }
  20% {
    -webkit-transform: rotate(-225deg) translate(-10px, 10px);
  }
  40% {
    -webkit-transform: rotate(-225deg) translate(0, 0);
  }
}
@keyframes totop {
  0% {
    transform: rotate(-225deg) translate(0, 0);
  }
  20% {
    transform: rotate(-225deg) translate(-10px, 10px);
  }
  40% {
    transform: rotate(-225deg) translate(0, 0);
  }
}
#email-icon {
  transition: all ease 0.5s;
}

#email-icon:hover {
  transform: scale(1.2);
  transition: all ease 0.5s;
}

#git-icon {
  transition: all ease 0.5s;
}

#git-icon:hover {
  transform: scale(1.2);
  transition: all ease 0.5s;
}
